#outerSearch{
    position: relative;
}
#outerSearch .searchList{
    position: absolute;
    top: 2rem;
    z-index: 2;
    right: 0;
    width: 0rem;
    height: 0rem;
    border-radius: 4px;
    background-color: white;
    background-color: black;
    // border: solid 1px black;
    box-shadow: 0 0 10px 0px #00000026;
    background-color: #e6f2ff;
    transition: 500ms ease;
    overflow: scroll;
}
#outerSearch .searchList.open{
    width: 18rem;
    height: 18rem;
} 
#outerSearch .searchList .itemList{
    width: 100%;
    min-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #bfc7d0;
    transition: 200ms ease;
    cursor: pointer;
}
#outerSearch .searchList .itemList:nth-child(1){
    border-top: none;
}
#outerSearch .searchList .itemList:hover{
    background-color: #bfc7d0;
}
#outerSearch .searchList .itemList .searchLeft{
    width: 80%;
}
#outerSearch .searchList .itemList .searchLeft .title{
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
#outerSearch .searchList .itemList .searchLeft .category{
    font-size: .7rem;
}
#outerSearch .searchList .itemList .searchRight{
    width: 10%;
    font-size: .8rem;
    text-align: end;
}
#outerSearch .search{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#outerSearch .search input{
    border-radius: 0.3rem;
    outline: none;
    border: solid 1px #101820;
    border: solid 1px gray;
    padding: .3rem .4rem;
    font-size: .8rem;
    padding-right: 1.5rem;
    text-align: center;
    background: transparent;
}
#outerSearch .search .icon{
    position: absolute;
    font-size: 1.15rem;
    display: flex;
    color: gray;
    align-items: center;
    margin-right: .3rem;
}