@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
$cream-color: #DDDDDD;
$white-color: #FFFFFF;
$base-color: #80A8D0;
$base-color: #80a8d077;
$black-color: #101820;
*{
  font-family: 'Montserrat', sans-serif;
  --test: #00ffff13;
}
body {
  margin: 0;
  min-height: 100vh;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Laila', sans-serif;
  background: linear-gradient(180deg, #222222 0%, #003C3C 100%);
  background: $black-color;
  overflow-x: hidden;
  // color: white;
}
body.overflowNone{
  max-width: 100%;
  overflow-x: hidden;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width:500px) {
  *{
    font-size: 70%;
  }
}

@media (max-width:1280px) {
  *{
    font-size: 90%;
  }
}