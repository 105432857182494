
// html {
//     // overflow-y: scroll;
//     overflow-y: overlay;
//     overflow-x: scroll;
//     overflow-x: overlay;
//   }

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gainsboro;
    background: transparent;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(71, 71, 71);
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  


  @media (max-width:768px) {
/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 6px;
}
}
  @media (max-width:500px) {
/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 3px;
}
}
  