nav{
    // height: 7rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    // z-index: 0;
}
nav .left{
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 10%;
    width: 100%;
    height: 100%;
}
nav .left .logo{
    height: 60%;
    // padding: 1rem;
    z-index: 0;

}
nav .right{
    // width: 80%;
    width: 0%;
    font-size: 1rem;
    position: absolute;
    top: 3.5rem;
    right: 0;
    // width: 30vmin;
    width: 100%;
    margin: 0 6%;
    text-align: end;
    // padding: 3.5rem;
    color: #ffffff;
    // text-align: end;
}
nav .right a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}
nav .right .logo{
    height: 60%;
    padding: 1rem;
}
@media (max-width:500px) {
    nav .left .logo{
        height: 30%;
    }
}