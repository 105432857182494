#PortfolioFilter.close{
    opacity: 0;
    transition: 300ms ease;
}
#PortfolioFilter{
    height: 100vh;
    position: fixed;
    // width: 100vw;
    width: 90%;
    display: flex;
    align-items: center;
    background-color: #101820;
    color: white;
    opacity: 1;
    z-index: 3;
    display: none;
    transition: 300ms ease;
}

#PortfolioFilter .right{
    // margin-left: 6rem;
    // margin-right: 6rem;
    margin-top: 4rem;
    // width: calc(100% - 12rem);
    width: calc(100% - 8rem);
    height:  calc(100% - 4rem);

    display: flex;
    flex-direction: column;
    text-align: start;
}
#PortfolioFilter .right .small{
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: 'Laila', sans-serif;
    width: 100%;
}
#PortfolioFilter .right .small::before {
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: -1rem;
    border-radius: 50%;
    content: '';
    background-color: white;
}
#PortfolioFilter .right .heading{
    font-size: 3.5rem;
    display: flex;
    align-items: center;
    font-weight: lighter;
    margin-bottom: 1rem;
    width: 100%;
}
#PortfolioFilter .right .middle{
    display: flex;
    height: 5rem;
    align-items: center;
}
#PortfolioFilter .right .middle .button{
    width: 5rem;
    display: flex;
    justify-content: center;
}
#PortfolioFilter .right .middle .button button{
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    color: #101820;
    // margin-top: .3rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    transition: 200ms ease;
}
#PortfolioFilter .right .middle .button button:hover{
    transform: scale(1.2);
}
#PortfolioFilter .right .middle .button button:active{
    transform: scale(1.1);
    
}
#PortfolioFilter .right .middle .button button:disabled{
    transform: scale(1.1);
    color: #bebebe8f;
}
#PortfolioFilter .right .slider{
    width: calc(100% - 10rem);
    overflow: auto;
    display: flex;
    align-items: center;
    height: 100%;
}

#PortfolioFilter .right .slider .title{
    // width: 3rem;
    font-size: 1rem;
    font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #DDDDDD;
    color: black;
    text-align: center;
    border-radius: .5rem;
    padding: 0.5rem .8rem;
    cursor: pointer;
    transition: 300ms;
}
#PortfolioFilter .right .slider .title:hover{
    transform: scale(.95);
}
#PortfolioFilter .right .slider .title:active{
    // transform: scale(.9);
}
#PortfolioFilter .right .slider .title.active{
    box-shadow: inset 0 0 0 5px #80A8D0;
}
#PortfolioFilter .right .lower{
    display: flex;
    height: 100%;
    overflow: auto;
    margin-top: 2rem;
    justify-content: center;
}
#PortfolioFilter .right .lower .container{
    display: grid;
    overflow: auto;
    height: 100%;
    width: calc(100% - 10rem);
    grid-template-columns: 12rem 12rem 12rem 12rem;
    row-gap: 50px;
    transition: 300ms ease;
    justify-content: space-between;
    // grid-template-columns: auto auto auto auto;
    // gap: 50px;
}
#PortfolioFilter .right .lower .container .box{
    // height: 11rem;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    // background-color: #80A8D0;
    // padding: .5rem;
    position: relative;
    text-align: center;
    transition: 300ms ease;
}
#PortfolioFilter .right .lower .container .box:hover > .hover{
    opacity: 1;
}
#PortfolioFilter .right .lower .container .box .hover{
    opacity: 0;
    cursor: pointer;
    position: absolute;
    background-color: #101820d1;
    width: 100%;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;  
    transition: 300ms ease;
}
#PortfolioFilter .right .lower .container .box .hover .span{
    margin-bottom: -3px;
    font-size: 1.1rem;
}
#PortfolioFilter .right .lower .container .box img{
    width: 100%;
    object-fit: contain;
    /* object-fit: cover; */
    height: 100%;
    border-radius: 0.3rem;
}
#PortfolioFilter .right .lower .container .box video{
    width: 100%;
    object-fit: contain;
    object-fit: cover;
    height: 100%;
    border-radius: 0.3rem;
    cursor: pointer;
}
@media (max-width:1300px) {
#PortfolioFilter .right .lower .container{
    grid-template-columns: 13rem 13rem 13rem;
    row-gap: 30px;
}
#PortfolioFilter .right .lower .container .box{
    height: 12rem;
}
#PortfolioFilter .right .lower .container{
    grid-template-columns: 15rem 15rem 15rem;
    row-gap: 30px;
}
#PortfolioFilter .right .lower .container .box{
    height: 15rem;
}
}
@media (max-width:1024px) {
#PortfolioFilter .right .lower .container{
    grid-template-columns: 11rem 11rem 11rem;
}
#PortfolioFilter .right .lower .container .box{
    height: 10rem;
}
}
@media (max-width:915px) {
#PortfolioFilter .right .lower .container{
    grid-template-columns: 13rem 13rem;
}
#PortfolioFilter .right .lower .container .box{
    height: 12rem;
}
}
@media (max-width:803px) {
#PortfolioFilter .right .lower .container{
    grid-template-columns: 11rem 11rem;
}
#PortfolioFilter .right .lower .container .box{
    height: 10rem;
}
}
@media (max-width:730px) {
#PortfolioFilter .right .lower .container{
    grid-template-columns: 11rem;
    justify-content: center;
}

}
@media (max-width:500px) {

    #PortfolioFilter{
        justify-content: center;
        height: 82%;
        width: 100%;
    }
    #PortfolioFilter .right{
        margin: 1rem 0;
        width: 100%;
        // width: calc(100% - 2rem);
    }
    #PortfolioFilter .right .small{
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
    #PortfolioFilter .right .heading{
        text-align: center;   
        justify-content: center;
        font-size: 3rem;
        word-spacing: 100vw;
        margin-bottom: 3rem;
        // margin: 2rem 0;
    }
    #PortfolioFilter .right .slider .title{
        font-size: .7rem;
    }
    // #PortfolioFilter .right .lower{
    // }
    #PortfolioFilter .right .lower .container{
        grid-template-columns: 9rem 9rem;
        width: 100%;
        // overflow: scroll;
        height: 100%;
        gap: 1rem;
        .box{
            height: 8rem;
        }
    }

}