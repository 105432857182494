.SwiperSection{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.SwiperSection .topNavigation{
    display: none;
    font-size: 1rem;
    color: white;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    transition: 300ms ease-in-out;
}
.SwiperSection .topNavigation.open{
    width: 90%;
}
.SwiperSection .topNavigation.open .leftbutton{
    opacity: 0;
}
.SwiperSection .topNavigation button{
    color: white;
    font-size: 1.3rem;
    padding: .5rem;
    border-radius: 50%;
    margin: .5rem 0;
    display: flex;
    transition: 200ms ease-out;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    // cursor: pointer;
    cursor: auto;
    // background-color: #676767;
}
.SwiperSection .topNavigation button:active{
    transform: scale(1.3) rotate3d(1, 1, 1, 10deg);;
    font-weight: bolder;
    box-shadow: 0 0 3px 1px #00000074;
    background-color: #ffffff32;
    transform: scale(1.2);
    // background-color: #e2e2e2;
}
.SwiperSection .topNavigation .burgerMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 88%;
    width: 87%;
}
.SwiperSection .left{
    width: 10%;
    height: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
}
.SwiperSection .right{
    width: 90%;
    height: 100%;
}
@media (max-width:500px) {
    .SwiperSection .topNavigation{
        height: 10%;
        display: flex;
    }
    .SwiperSection .left{
        height: 90%;
    }
}