// background-color: rgba(0, 255, 255, 0.137);

.socialMediaDiv{
    // background-color: rgba(0, 255, 255, 0.137);
    width: 8rem;
    position: absolute;
    display: flex;
    // position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin: 0 4rem;
    z-index: 2;

}
.socialMediaDiv .line{
    height: 10vh;
    margin: 2rem 0;
    width: .13rem;
    background-color: white;
    border-radius: 1rem;
}
.socialMediaDiv a{
    color: white;
    font-size: 1.3rem;
    padding: .5rem;
    border-radius: 50%;
    margin: .5rem 0;
    display: flex;
    transition: 200ms ease-out;
    text-decoration: none;
    // background-color: #676767;
}
.socialMediaDiv a:hover{

    transform: scale(1.3);
    background-color: #ffffff;
    font-weight: bolder;
    color: #86888a;
}
.socialMediaDiv a:active{
    transform: scale(1.2);
    background-color: #e2e2e2;
}
// .socialMediaDiv a:nth-child(2):hover{
//     color: #00acee;
// }
// .socialMediaDiv a:nth-child(3):hover{
//     color: #caccce;
//     color: #86888a;
// }
// .socialMediaDiv a:nth-child(4):hover{
//     color: #4267B2;
// }
// .socialMediaDiv a:nth-child(5):hover{
//     color: #bc2a8d;
// }
@media (max-width:500px) {
    .socialMediaDiv{
        display: none;
    }
}