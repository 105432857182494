#HomePaginationMain{
    // justify-content: center;
    // align-items: center;
    // display: flex;
    height: 100vh;
}
#HomePaginationMain .swiper-pagination-bullet{
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,10px));
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,20px));
    border-radius: 5rem;
    transition: 300ms ease;
    background-color: white;
    opacity: .2;
    @media (max-width:500px) {
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,5px));
    }
  }
  #HomePaginationMain .swiper-pagination-bullet.swiper-pagination-bullet-active{
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,50px));
    opacity: 1;
  }
  
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    // background-color: rgb(0, 177, 177);
}
.App-header{
    background-color: rgb(0, 90, 60);
    z-index: 5;
    min-height: 100vh;
    position: absolute;
}
.Home{
    height: 100vh;
    overflow: hidden;
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory;
}
// .OurterGo section{
//     height: 100vh;
//     scroll-snap-align: start;
// }
.outerGo .innerGo{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    box-shadow: 0 0 15px 2px  #00000029;
    background-image: linear-gradient( 102.4deg,  rgba(253,189,85,1) 7.8%, rgba(249,131,255,1) 100.3% );
    background-image: linear-gradient( 68.4deg,  rgba(99,251,215,1) -0.4%, rgba(5,222,250,1) 100.2% );
    font-size: 25rem;
    scroll-snap-align: start;
  }
.innerGo{
    height: 100vh;
    box-shadow: 0 0 15px 2px  #00000029;
}
.innerGo .text{
    width: 50vw;
    height: 30vw;
    // margin-top: 50vw;
    background-color: rgba(0, 255, 255, 0.37);
}

.innerGo.color{
    background-color: rgb(255, 0, 0);
    box-shadow: 0 0 20px 5px  #0000006c;
    // box-shadow: 0 0 15px 2px  #00000029;

    background: #101820;
    //   background-color: #101820;
  }
.innerGo .scrollDownDiv{
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 4vh;
    color: white;
    font-size: .8rem;
    text-align: center;
    left: 0;
    right: 0;
    pointer-events: none;
    // cursor: pointer;
    // z-index: 5;
    animation: scrolldownDiv 1.1s infinite;
}
@keyframes scrolldownDiv {
    0%{
        color: white;
    }
    59.9%{
        color: white;
    }
    60%{
        color: transparent;
    }
    100%{
        color: transparent;
    }
}

.innerGo .scrollDownDiv span{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width:500px) {
    .innerGo .scrollDownDiv{
        // bottom: 10px;
        bottom: 9vh;
    }
    .innerGo{
        height: 99vh;
    }
}