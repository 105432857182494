@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
$test :#80a8d04b;

:root {
    --button-width: 1.5rem;
    --button-height: calc(var(--button-width) * .8);
    --rectangle-height: calc(var(--button-width) * .1);
    --rectangle-radius: calc(var(--button-width) * .04);
    --translate: calc(var(--button-width) * .17);
    --transition: 0.65s;
  }
.lowerHome{
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    position: absolute;
    color: white;
}
.lowerHome .upperSpace{
    height: 20%;
}
.lowerHome .upperSpace .right{
    font-size: 1rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 7rem;
}
.lowerHome .upperSpace .right .burgerMenu{
    display: none;
    justify-content: flex-end;
    align-items: center;
    width: 88%;
    width: 87%;
}
.lowerHome .upperSpace .right .inner{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 88%;
    width: 87%;
}
.lowerHome .upperSpace .right a{
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.lowerHome .upperSpace .right a:hover{
    transform: skewX(-10deg);
    background-color: #2a281d;
    box-shadow: 0 0 0 10px #2a281d;
    border-radius: 1px;
}
.lowerHome .lowerSpace{
    height: 80%;
    width: 100%;
    // background-color: rgba(0, 255, 255, 0.247);
    display: flex;
}

.lowerHome .lowerSpace .left{
    width: 65%;
    width: 0%;
    // width: 8rem;
    display: flex;
    // justify-content: flex-end;
}
.lowerHome .lowerSpace .goUp{
    // width: 28rem;
    position: absolute;
    width: 73vw;
    margin-top: 3.5%;

    // margin-top: 1%;
}
.lowerHome .lowerSpace .goUp .rotaterWeb{
    display: block;
}
.lowerHome .lowerSpace .goUp .rotaterMobile{
    display: none;
}
.lowerHome .lowerSpace .right{
    width: 30%;
    width: 100%;
    margin: 0 6%;
    margin-top: 4%;
    // background-color: rgba(0, 255, 255, 0.218);
    text-align: end;
    // margin-right: 5%;
}

//Welcome
.lowerHome .lowerSpace .right .welcome{
    line-height: 1;
    // margin-bottom: 2rem;
}
.lowerHome .lowerSpace .right .welcome .upper{
    font-size: 4rem;    
    line-height: 3rem;
}
.lowerHome .lowerSpace .right .welcome .lower{
    font-size: 1.8rem;    
    font-weight: 200;
}
.lowerHome .lowerSpace .right .about .upper{
    // margin: 1rem 0;
    margin-bottom: 1rem;
    font-size: .8rem;
    //test
    text-align: justify;
    margin-top: .5rem;
    margin-bottom: 2rem;
}

//Portfolio
$eye :3rem;
$eye :4vw;
.lowerHome .portfolio{
    z-index: 102;
    margin-top: .5rem;
    /* font-size: 30vw; */
    display: flex;
    font-size: $eye;
    color: #ffffff;
    letter-spacing: .4rem;
    justify-content: center;
    // background-color: $test;
    .innerPortfolio{
        
        font-family: 'Monoton', cursive;
        // margin-left: auto;
        display: flex;
        align-items: center;
        // background-color: $test;
    }
}

.lowerHome .portfolio span{
    // z-index: 1000;
    height: $eye;
    width: $eye;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lowerHome .portfolio span:nth-child(1){
    margin-right: .4rem;
}
.lowerHome .portfolio span .cropper{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lowerHome .portfolio span .cropper .circle{
    height: $eye;
    width: $eye;
    background-color: #80A8D0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.lowerHome .portfolio span .cropper .circle .black{
    // z-index: 1000;
    height: 30%;
    width: 30%;
    border-radius: 50%;
    background-color: black;
    animation: blackDot 3s;
}







//About
.lowerHome .lowerSpace .right .about{
    width: 70%;
    width: 20rem;
    margin-left: auto;
}
.lowerHome .lowerSpace .right .about .lower button{
    color: #101820;
    background-color: #DDDDDD;
    font-size: 1.2rem;
    padding: .5rem 1rem;
    border-radius: .3rem;
    cursor: pointer;
    transition: 300ms;
    font-weight: 600;
    border: none;
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 4px 4px rgba(0, 0, 0, 0.16);
}
.lowerHome .lowerSpace .right .about .lower button:disabled{
    // animation: knowMoreButton 2s linear infinite forwards;
    // animation: knowMoreButton 2s linear;
    animation: knowMoreButton 5.5s ease-in-out;
    // background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-color: white;
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    background: linear-gradient(90deg, #DDDDDD  0%, #2a281d 100%);
    background: linear-gradient(90deg, #DDDDDD  0%, #80A8D0 100%);
    background-size: 400%;
    // width: 9rem;
}
@keyframes knowMoreButton {
    0% {
        background-position: 0 0;
    }
    100% {
        // background-position: 9rem 0;
        background-position: -100% 0;
      }
}
.lowerHome .lowerSpace .right .about .lower button:hover{
    color: #000000;
    box-shadow: none;
    // transform: scale(1.12);
    // transform: skew(1 ,2);
    transform: skew(-10deg) scale(1.12);

    // background-color: #dddddddf;

}
.lowerHome .lowerSpace .right .about .lower button:active{
    background-color: #fdfdfde4;
    transform: skew(-8deg) scale(1.08);

}
@media (max-width:500px) {
    $portfolio :10vw;
    
    .lowerHome .upperSpace{
        height: 15%;
    }
    .lowerHome .upperSpace .right .burgerMenu{
        display: flex;
    }
    .lowerHome .upperSpace .right .inner{
        display: none;
    }
    .lowerHome .lowerSpace .goUp{
        bottom: 10vh;
        width: 100vw;
    }
    .lowerHome .lowerSpace .goUp .rotaterWeb{
        display: none;
    }
    .lowerHome .lowerSpace .goUp .rotaterMobile{
        display: block;
    }
    // .lowerHome .lowerSpace .goUp .rotater .circleContainer{
    //     height: 57vw;
    // }
    .lowerHome .lowerSpace .goUp .rotater .circle{
        width: 75vw;
        height: 75vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .circle .innerbox{
        width: 15vw;
        height: 15vw;
        margin-top: 28.5vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .belowDiv {
        margin-top: 25vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .belowDiv .box{
        width: 38vw;
        height: 38vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .navigationBUtton{
        width: 85vw;
    }
    .lowerHome .lowerSpace .right{
        text-align: center;
    }
    .lowerHome .portfolio{
        font-size: $portfolio;
    }
    .lowerHome .portfolio .innerPortfolio{
        margin-left: auto;
        margin-right: auto;
    }
    .lowerHome .lowerSpace .right .welcome .upper{
        font-size: 3.5rem;
    }
    .lowerHome .lowerSpace .right .welcome .lower{
        font-size: 1.5rem;
    }
    .lowerHome .lowerSpace .right .about{
        width: 100%;
    }
    .lowerHome .lowerSpace .right .about .upper{
        font-size: .7rem;
        font-size: .5rem;
        font-size: .5rem;
        margin-bottom: 1rem;
    }
    .lowerHome .lowerSpace .right .about .lower button{
        font-size: 1rem;
    }
    .lowerHome .portfolio span{
        height: $portfolio;
        width: $portfolio;
    }
    .lowerHome .portfolio span .cropper .circle{
        height: $portfolio;
        width: $portfolio;
    }
    
}