// .logoDiv{
//     height: 5rem;
// }
#BurgerMenu {
    .fancy-burger {
        position: relative;
        width: var(--button-width);
        height: var(--button-height);
        border: 0;
        background: transparent;
        outline: none;
        z-index: 4;
        // -webkit-transform: scaleX(-1);
        // transform: scaleX(-1);
      }
      .rectangle {
        position: absolute;
        height: var(--rectangle-height);
        background: #DDDDDD;
        border-radius: var(--rectangle-radius);
        transition: transform var(--transition);
      }
      .rectangle--small {
        width: calc(var(--button-width) / 2);
      }
      .rectangle--top {
        top: 0;
        left: 0;
        transform-origin: top left;
      }
      .rectangle--top.open {
        transform: translateX(var(--translate)) rotate(45deg);
      }
      .rectangle--middle {
        top: 50%;
        left: 0;
        width: var(--button-width);
        transform: translatey(-50%);
      }
      .rectangle--middle.open {
        transform: translatey(-50%) rotate(-45deg);
      }
      .rectangle--bottom {
        bottom: 0;
        right: 0;
        transform-origin: bottom right;
      }
      .rectangle--bottom.open {
        transform: translateX(calc(-1 * var(--translate))) rotate(45deg);
      }
      .box {
        position: absolute;
        // top: 34px;
        // left: 40px;
        width: 400px;
        height: 280px;
        z-index: 4;
        border-radius: 33px;
        // background: #5672fc;
        background: #101820;
        box-shadow: 0 0 0 10vw #ffffff80;
        opacity: 0;
        transform: scale(0);
        transform-origin: top left;
        transition-duration: var(--transition);
        top: 0;
        height: 100vh;
        width: 90vw;
        border-radius: 0;
        right: -100vw;
        transform: scale(1);
    }
    .box.open {
        right: 0vw;
        opacity: 1;
        transform: scale(1);
      }
    .box .outer .inner{
          display: flex;
          flex-direction: column;
    }
    .box .outer .inner .logo{
        height: 7rem;
    }
    .box .outer .inner .logo img{
        height: 60%;
    }
}
#BurgerMenu .BurgerOuter {
    height: 100%;
}
#BurgerMenu .inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}
#BurgerMenu .inner .logoDiv img{
    height: 5rem;
    margin-top: 3rem;
}
#BurgerMenu .inner .title{
    font-size: 1.8rem;
    margin-bottom: 1rem;
}
#BurgerMenu .inner .outerList{
    font-size: 1rem;
    width: 100%;
}
#BurgerMenu .inner .outerList .list{
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    border-top: solid 1px black;
    transition: 100ms ease;
    // background-color: rgb(49, 46, 42);
}
#BurgerMenu .inner .outerList .list span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    transform: rotate(90deg);
    // position: absolute;
    transition: 300ms ease-in-out;
}
#BurgerMenu .inner .outerList .list span.open{
    transform: rotate(-90deg);
}
#BurgerMenu .inner .outerList .list:active{
    background-color: #172b40;
}

#BurgerMenu .inner .outerList .innerlist{
    font-size: 1rem;
    width: 100%;
    height: 0rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 300ms ease-in-out;
}
#BurgerMenu .inner .outerList .innerlist.open{
    height: 12rem;
}
#BurgerMenu .inner .outerList .innerlist .listItems{
    font-size: .8rem;
    padding: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 3rem;
    border-top: solid 1px black;
    transition: 100ms ease;
    border-radius: 2px;
}
#BurgerMenu .inner .outerList .innerlist .listItems:active{
    background-color: #172b40;
}