.rotater{
    // width: 100vw;
    // // height: 100vh;
    // width: 30rem;
    // height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-wrap: wrap;
}
.rotater{

.circleContainer{
    width: 100%;
    height: 15rem;
    height: 22rem;
    height: 20vw;
    height: 21vw;
    height: 23vw;
    min-height: 16rem;
    margin-top: auto;
    display: flex;
    overflow: hidden;
    justify-content: center;
    //test
    // height: 41vw;
    // margin-top: -15rem;
    @media (max-width:1350px) {
        height: 24vw;
    }
    @media (max-width:1116px) {
        height: 25vw;
    }
    @media (max-width:950px) {
        height: 26vw;
    }
    @media (max-width:770px) {
        height: 27vw;
        min-height: 13rem;
    }
    @media (max-width:610px) {
        min-height: 12rem;
    }

}
.circleinner{
    width: 100%;
    height: 20rem;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
}

.circle{
    width: 22rem;
    height: 22rem;
    width: 35rem;
    height: 35rem;
    width: 35vw;
    height: 35vw;
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: dashed 2px;
    border-radius: 50%;
    transition: 800ms ease-in-out;
    transform: rotate(-6deg);
}
.box{
    position: absolute;
    border-radius: 50%;
}
.innerbox{
    position: absolute;
    width: 5rem;
    height: 5rem;
    width: 7rem;
    height: 7rem;
    width: 7vw;
    height: 7vw;
    font-size: 1rem;
    border: solid 3px white;
    margin-top: 8rem;
    margin-top: 13.5vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 800ms ease-in-out;
    background-color: #DDDDDD;
}
.innerbox.LogoSmall{
    border: solid 6px #88C7F5;
}
.innerbox.AppDevSmall{
    border: solid 6px #BD7087;
}
.innerbox.WebDevSmall{
    border: solid 6px #527295;
}
.innerbox.UiUxSmall{
    border: solid 6px #7C5494;
}
.innerbox.SocialMediaSmall{
    border: solid 6px #DA7F6B;
}
.innerbox img{
    // width: 60%;
    height: 60%;
}
// .innerbox:hover{
//     width: 6rem;
//     height: 6rem;
// }
$innerSet : 32deg;
.box:nth-child(1){
    transform: rotate(40deg*1);
    .innerbox{
        transform: rotate((40deg*1)-$innerSet);
    }
}
.box:nth-child(2){
    transform: rotate(40deg*2);
    .innerbox{
        transform: rotate((40deg*8)-$innerSet);

    }
}
.box:nth-child(3){
    transform: rotate(40deg*3);
    .innerbox{
        transform: rotate((40deg*7)-$innerSet);
    }
}
.box:nth-child(4){
    transform: rotate(40deg*4);
    .innerbox{
        transform: rotate((40deg*6)-$innerSet);
    }
}
.box:nth-child(5){
    transform: rotate(40deg*5);
    .innerbox{
        transform: rotate((40deg*5)-$innerSet);
    }
}
.box:nth-child(6){
    transform: rotate(40deg*6);
    .innerbox{
        transform: rotate((40deg*4)-$innerSet);
    }
}
.box:nth-child(7){
    transform: rotate(40deg*7);
    .innerbox{
        transform: rotate((40deg*3)-$innerSet);
    }
}
.box:nth-child(8){
    transform: rotate(40deg*8);
    .innerbox{
        transform: rotate((40deg*2)-$innerSet);
    }

}
.box:nth-child(9){
    transform: rotate(40deg*9);
    .innerbox{
        transform: rotate((40deg*1)-$innerSet);
    }
}
// .box:nth-child(10){
//     transform: rotate(40deg*10);
//     .innerbox{
//         transform: rotate((40deg*4)-$innerSet);
//     }
// }
.belowDiv{
    width: 100%;
    position: absolute;
    margin-top: 18vw;
    margin-top: 10vw;   
    margin-top: 13vw;   
    margin-top: 12vw;   
    
}
.belowDiv .swiperCircle .swiper-slide{
    overflow: visible;
}
.belowDiv .box{
    width: 10vw;
    height: 10vw;
    width: 12vw;
    height: 12vw;
    width: 16vw;
    height: 16vw;
}
.belowDiv .swiper-fade  .box {
    transform: scale(0);
    transition: 800ms ease;
}
.belowDiv .swiper-fade .swiper-slide-active .box {
    transform: scale(1);
}
.belowDiv .swiper-fade .swiper-slide-active .box:hover {
    cursor: pointer;
    transition: 300ms ease;

    transform: scale(1.1);
}
.belowDiv .swiper-fade .swiper-slide-active .box:active {
    transform: scale(1.05);
}

.navigationBUtton{
    width: 80%;
    width: 26rem;
    z-index: 2;
    width: 39vw;
    width: 38vw;
    margin-bottom: auto;
    pointer-events: none;
}
.navigationBUtton .button{
    outline: none;
    pointer-events: auto;
    border: none;
    font-size: 1.6rem;
    width: 3rem;
    height: 3rem;
    background-color: #DDDDDD;
    transition: 200ms ease-out;
}
.navigationBUtton button{
    outline: none;
    pointer-events: auto;
    border: none;
    padding: 0;
    font-size: 1.6rem;
    // font-size: 1.2rem;
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    // width: 2rem;
    // height: 2rem;
    background-color: #DDDDDD;
    transition: 200ms ease-out;
    color: black;
}
.navigationBUtton .buttonInner:hover {
    background-color: #595954;
    color: white;
}
.navigationBUtton .buttonInner:disabled {
    background-color: #DDDDDD;
    color: #8d8d8d;
}
.navigationBUtton .button{
    outline: none;
    pointer-events: auto;
    border: none;
    font-size: 1.6rem;
    width: 3rem;
    height: 3rem;
    background-color: #DDDDDD;
    transition: 200ms ease-out;
}
.navigationBUtton button:hover{   
    transform: scale(1.2);

}
.navigationBUtton button:active{   
    transform: scale(1.1);

}
.navigationBUtton button:disabled:hover{   
    background-color: #9a9a9a;
    transform: scale(1.2);

}
.navigationBUtton button:disabled:active{   
    background-color: #c1c1c1;
    transform: scale(1.1);
}

}

@media (min-width:1800px) {
    .rotater .circle{
        height: 36vw;
    }
}
@media (max-width:500px) {
    .rotater .circleContainer{
        height: 56vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .circle{
        width: 70vw;
        height: 70vw;
    }
    .lowerHome .lowerSpace .goUp .rotater .circle .innerbox {
        width: 15vw;
        height: 15vw;
        margin-top: 25.5vw;
    }
}