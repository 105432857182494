
#HomePagination {
    // margin: 100px auto;
    // width: 100vw;
    // height: 100vh;
    // overflow: hidden;
  }
  
  // .swiper-slide {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 22px;
  //   font-weight: bold;
  //   color: #fff;
  // }
  
  // .swiper-slide:nth-child(1n) {
  //   background-color: rgb(206, 17, 17);
  // }
  
  // .swiper-slide:nth-child(2n) {
  //   background-color: rgb(0, 140, 255);
  // }
  
  // .swiper-slide:nth-child(3n) {
  //   background-color: rgb(10, 184, 111);
  // }
  
  // .swiper-slide:nth-child(4n) {
  //   background-color: rgb(211, 122, 7);
  // }
  
  // .swiper-slide:nth-child(5n) {
  //   background-color: rgb(118, 163, 12);
  // }
  
  // .swiper-slide:nth-child(6n) {
  //   background-color: rgb(180, 10, 47);
  // }
  
  // .swiper-slide:nth-child(7n) {
  //   background-color: rgb(35, 99, 19);
  // }
  
  // .swiper-slide:nth-child(8n) {
  //   background-color: rgb(0, 68, 255);
  // }
  
  // .swiper-slide:nth-child(9n) {
  //   background-color: rgb(218, 12, 218);
  // }
  
  // .swiper-slide:nth-child(10n) {
  //   background-color: rgb(54, 94, 77);
  // }
  #HomePagination .swiper-pagination-bullet{
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,10px));
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,20px));
    border-radius: 5rem;
    transition: 300ms ease;
    background-color: white;
    opacity: .2;
    @media (max-width:500px) {
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,5px));
    }
  }
  #HomePagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,50px));
    opacity: 1;
  }
  