
.navigationButtons{
    // background-color: rgba(0, 255, 255, 0.137);
    width: 8rem;
    position: absolute;
    display: flex;
    // position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin: 0 4rem;
    z-index: 5;

}
.navigationButtons .line{
    height: 10vh;
    margin: 2rem 0;
    width: .13rem;
    background-color: white;
    border-radius: 1rem;
}
.navigationButtons button{
    color: white;
    font-size: 1.3rem;
    padding: .5rem;
    border-radius: 50%;
    margin: .5rem 0;
    display: flex;
    transition: 200ms ease-out;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 300ms ease;
    // background-color: #676767;
}
.navigationButtons button.active{
    // width: 8rem;
    // padding: .8rem;
    // padding: 1rem;
    background-color: white;
    color: black;
    border-radius: 0;
    justify-content: center;
    transform: scale(1.2);
    // pointer-events: none;
}
// .navigationButtons button.active:hover{
//     transform: scale(1.3) rotate3d(1, 1, 1, 0deg);;
// }
.navigationButtons button:hover{
    transform: scale(1.3);
    transform: scale(1.3) rotate3d(1, 1, 1, 10deg);;
    // transform: scale(1.3) rotate(45deg);;
    background-color: #ffffff;
    font-weight: bolder;
    box-shadow: 0 0 3px 1px #00000074;
}
.navigationButtons button:active{
    transform: scale(1.2);
    // background-color: #e2e2e2;
}
.navigationButtons button:nth-child(2):hover{
    color: #00acee;
}
.navigationButtons button:nth-child(3):hover{
    color: #caccce;
    color: #86888a;
}
.navigationButtons button:nth-child(4):hover{
    color: #4267B2;
}
.navigationButtons button:nth-child(5):hover{
    color: #bc2a8d;
}


@media (max-width:500px) {
    .navigationButtons{
        display: none;
    }
}