#MadeOfIdea.close{
    opacity: 0;
    transition: 300ms ease;
}
#MadeOfIdea{
    height: 100vh;
    position: fixed;
    width: 90%;
    display: flex;
    align-items: center;
    color: white;
    opacity: 1;
    z-index: 3;
    background-color: #101820;
    transition: 300ms ease;
    display: none;
}

#MadeOfIdea.open{
    opacity: 1;
}
#MadeOfIdea .right{
    // margin-left: 6rem;
    margin-right: 8rem;
    margin-top: 4rem;
    // width: calc(100% - 12rem);
    // width: calc(100% - 30rem);
    width: 80%;
    width: calc(100% - 8rem);
    width: 100%;
    height:  calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    text-align: start;
}
#MadeOfIdea .right .small{
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: 'Laila', sans-serif;
    width: 100%;
}
#MadeOfIdea .right .small::before {
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: -1rem;
    border-radius: 50%;
    content: '';
    background-color: white;
}
#MadeOfIdea .right .heading{
    font-size: 3.5rem;
    display: flex;
    align-items: center;
    font-weight: lighter;
    width: 100%;
}

#MadeOfIdea .right .middle{
    display: flex;
    // height: 20rem;
    flex-direction: column;
}
#MadeOfIdea .right .middle .title{
    margin: 1.5rem 0;
    font-size: 1rem;
}
#MadeOfIdea .right .middle .details{
    font-size: 1rem;
    line-height: 2;
    font-weight: lighter;
}


#MadeOfIdea .right .lower{
    display: flex;
    height: 100%;
    overflow: auto;
    margin-top: 2rem;
    justify-content: center;
}
#MadeOfIdea .right .lower .container{
    display: grid;
    overflow: auto;
    height: 100%;
    width: calc(100% - 10rem);
    width: 100%;
    grid-template-columns: 12rem 12rem 12rem 12rem;
    row-gap: 50px;
    transition: 300ms ease;
    justify-content: space-between;
    // grid-template-columns: auto auto auto auto;
    // gap: 50px;
}
#MadeOfIdea .right .lower .container .box{
    cursor: pointer;
    margin: 1rem 0;
    height: 14rem;
    // aspect-ratio: 1.1 / 1;
    border-radius: .3rem;
    font-size: 1rem;
    text-align: center;
    transition: 300ms ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#MadeOfIdea .right .lower .container .box:hover{
    // transform: scale(1.1);
    margin-top: .5rem;
}
#MadeOfIdea .right .lower .container .box .image{
    height: 11rem;
    border-radius: 0.3rem;
    background-color: #80A8D0;
    display: flex;
    justify-content: center;
    align-items: center;
}
#MadeOfIdea .right .lower .container .box .image img{
    height: 65%;
}
#MadeOfIdea .right .lower .container .box .title{
    background-color: white;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: .5rem;
    border-radius: .3rem;

}
@media (max-width:925px) {
    #MadeOfIdea .right .lower .container{
        grid-template-columns: 12rem 12rem 12rem;
        // row-gap: 50px;
    }
    
}
@media (max-width:690px) {
    #MadeOfIdea .right .lower .container{
        grid-template-columns: 13rem 13rem;
    }
    
}
@media (max-width:768px) {
    // #MadeOfIdea .right .lower .container{
    //     grid-template-columns: 13rem 13rem;
    // }
    #MadeOfIdea .right .heading{
        font-size: 3rem;
    }
    #MadeOfIdea .right .middle .title {
        margin: 1rem 0;
    }
    #MadeOfIdea .right .middle .details{
        line-height: 1.5;
        font-size: .8rem;
    }
    
}
@media (max-width:500px) {

    
    #MadeOfIdea{
        justify-content: center;
        height: 90%;
        width: 100%;
        height: 82%;
        margin-bottom: 2rem;
    }

    #MadeOfIdea .right{
        margin: 1rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        // width: calc(100% - 2rem);
    }
    #MadeOfIdea .right .small{
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }

    #MadeOfIdea .right .heading{
        text-align: center;   
        justify-content: center;
        font-size: 2rem;
        // word-spacing: 100vw;
        // margin-bottom: 3rem;
        // margin: 2rem 0;
        width: 85%;
    }

    #MadeOfIdea .right .middle{
        align-items: center;
    }
    #MadeOfIdea .right .middle .details{
        width: 85%;
        font-size: .8rem;
        line-height: 1.2;
        text-align: center;
    }
    #MadeOfIdea .right .lower{
        width: 100%;
    }
    #MadeOfIdea .right .lower .container{
        grid-template-columns: 9rem 9rem;
        width: calc(100% - 2rem);
        width: 85%;
        
        row-gap: .3rem;
        overflow: initial;
        // row-gap: 0rem;
    }
    #MadeOfIdea .right .lower .container .box{
        height: 11rem;
        margin: .5rem 0;
    }
    #MadeOfIdea .right .lower .container .box .image{
        height: 8rem;
    }
}