$delay : 1000ms;
.CreativitySwiper.close{
    opacity: 0;
}

.CreativitySwiper{
    width: 100%;
    height: 100%;
    color: white;
    opacity: 1;
    // display: grid;
    // display: flex;
    // align-content: center;
}

#creativitySwiper .swiper-slide{
    overflow: visible !important;
}
.CreativitySwiper #creativitySwiper{
    width: 100%;
    height: 100%;
    color: white;
}
#creativitySwiper .InnerSwiper{
    width: 100%;
    height: 80%;
    margin: 5% 0;
    display: flex;
}
#creativitySwiper .InnerSwiper .left{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: space-between;
}
#creativitySwiper .InnerSwiper .left .upper{
    width: 100%;
    margin-top: -60%;
    transition: $delay ease-in-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .left .upper{
    margin-top: 0%;
    transition: $delay ease-in-out;

}
#creativitySwiper .InnerSwiper .left .upper .static{
    margin-left: -1rem;
    font-size: 1rem;
    position: relative;
}


#creativitySwiper .InnerSwiper .left .upper .static::before{
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: -1rem;
    margin-top: 0.3rem;
    border-radius: 50%;
    content: '';
    background-color: #fafafa;
}
#creativitySwiper .InnerSwiper .left .upper .title{
    font-size: 4.5rem;
    font-weight:300;
}
#creativitySwiper .InnerSwiper .left .middle{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 20rem;
}
#creativitySwiper .InnerSwiper .left .middle .outerImg{
    width: 60%;
    height: 80%;
}
#creativitySwiper .InnerSwiper .left .middle .outerImg .img{
    // background-color: rgba(0, 255, 255, 0.1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // margin-bottom: 2rem;
}
#creativitySwiper .InnerSwiper .left .middle .outerImg .img .imageBorder{
    width: 100%;
    width: 25rem;
    width: 22rem;
    height: 100%;
    border: solid 2px white;
    border-radius: 20%;
    margin-left: -150%;
    transform: scale(.5);
    transition: $delay ease-in-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .left .middle .outerImg .img .imageBorder{
    margin-left: 0%;
    transform: scale(1);
    transition: $delay ease-in-out;
}
#creativitySwiper .InnerSwiper .left .middle .outerImg .img img{
    // position: relative;
    position: absolute;
    width: 80%;
    // width: 8rem;
    top: -175%;
    right: -150%;
    transform: rotate3d(1, 1, 1, 200deg);
    transition: $delay ease-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .left .middle .outerImg .img img{
    // top: -75%;
    // top: 0;
    top: auto;
    right: auto;
    transform: rotate3d(1, 1, 1, 0deg);
    transition: $delay ease-out;
}
#creativitySwiper .InnerSwiper .left .middle .button {
    width: 360%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    transition: $delay ease-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .left .middle .button {
    width: 60%;
    transition: $delay ease-out;
}
#creativitySwiper .InnerSwiper .left .middle .button button{
    height: 100%;    
    display: flex;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-radius: 5rem;
    color: black;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #DDDDDD;
    transition: 200ms ease-out;
    font-size: 1rem;
}
#creativitySwiper .InnerSwiper .left .middle .button button:hover{
    background-color: #9a9a9a;
    transform: scale(1.2);
}
#creativitySwiper .InnerSwiper .left .middle .button button:active{
    background-color: #c1c1c1;
    transform: scale(1.1);
}
#creativitySwiper .InnerSwiper .left .lower{
    width: 100%;
    // margin-top: -60%;
    margin-bottom: -70%;
    transition: $delay ease-in-out;

    // margin-bottom: -60%;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .left .lower{
    // margin-top: 0%;
    transition: $delay ease-in-out;
    margin-bottom: 0%;
}
#creativitySwiper .InnerSwiper .left .lower .details{
    font-size: 0.8rem;
    line-height: 2;
}
#creativitySwiper .InnerSwiper .left .lower .borrow{
    font-size: 2.3rem;
    margin: 1rem 0;
    font-weight:200;
}
#creativitySwiper .InnerSwiper .left .lower .button{
    margin-top: 1.5rem;
}
#creativitySwiper .InnerSwiper .right{
    width: 40%;
    margin-right: 10%;
    margin-left: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
#creativitySwiper .InnerSwiper .right .outerImg{
    height: 80%;
    margin-top: 8%;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#creativitySwiper .InnerSwiper .right .outerImg .img{
    height: 68%;
    width: 85%;
    height: 100%;
    /* border-radius: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}
#creativitySwiper .InnerSwiper .right .outerImg .imageBorder{
    height: 65%;
    width: 22%;
    /* margin-top: 20%; */
    /* height: 80%; */
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #9a9a9a;
    position: absolute;
    margin-right: -50rem;
    transform: scale(.5);
    transition: $delay ease-in-out;
}
#creativitySwiper  .swiper-slide-active .InnerSwiper .right .outerImg .imageBorder{
    margin-right: 0rem;
    transform: scale(1);
    transition: $delay ease-in-out;
}
#creativitySwiper .InnerSwiper .right .outerImg .img img{
    width: 85%;
    /* margin-bottom: 300%; */
    /* margin-right: 550%; */
    margin-bottom: 100%;
    margin-right: 550%;
    transform: rotate3d(1, 1, 1, 360deg);
    transform: rotate3d(1, 1, 1, 200deg);
    transition: $delay ease-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .right .outerImg .img img{
    margin-bottom: 0%;
    margin-right: 0%;
    transform: rotate3d(1, 1, 1, 0deg);
    transition: $delay ease-out;

}
#creativitySwiper .InnerSwiper .right .button{
    /* margin-top: auto; */
    display: flex;
    /* margin-bottom: 5rem; */
    /* height: 10%; */
    width: 55%;
    justify-content: space-between;
    justify-content: flex-end;
    margin-bottom: -10rem;
    transition: $delay ease-in-out;
}
#creativitySwiper .swiper-slide-active .InnerSwiper .right .button{
    margin-bottom: 0rem;
    transition: $delay ease-in-out;
}
#creativitySwiper .InnerSwiper .right .button .prev{
    margin-left: 6%;
}
#creativitySwiper .InnerSwiper .right .button button{
    height: 3rem;    
    width: 3rem;    
    height: 2.5rem;    
    width: 2.5rem;    
    // margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #DDDDDD;
    transition: 200ms ease-out;
    font-size: 1.3rem;
}
#creativitySwiper .InnerSwiper .right .button button:hover{
    background-color: #9a9a9a;
    transform: scale(1.2);
}

#creativitySwiper .InnerSwiper .right .button button:active{
    background-color: #c1c1c1;
    transform: scale(1.1);
}
@media (max-width:1100px) {
    .SwiperSection .left{
        width: 15%;
    }
    .SwiperSection .right{
        width: 85%;
    }
    #creativitySwiper .InnerSwiper .left .upper .title{
        font-size: 3rem;
    }
    #creativitySwiper .InnerSwiper .left .lower .details{
        font-size: 0.8rem;
        line-height: 1.6;
    }
    #creativitySwiper .InnerSwiper .left .lower .borrow{
        font-size: 1.8rem;
    }
    #creativitySwiper .InnerSwiper .left .lower .button{
        margin-top: 1rem;
    }
    #creativitySwiper .InnerSwiper .right .outerImg .imageBorder{
        width: 26%;
    }
    #creativitySwiper .InnerSwiper .right .outerImg .img img{
        width: 100%;
    }
}
@media (max-width:500px) {
    .SwiperSection{
        flex-direction: column;
    }
    .SwiperSection .left{
        width: 0%;
        height: 0%;
    }

    .SwiperSection .right{
        width: 100%;
        height: 90%;
    }
    #creativitySwiper .InnerSwiper{
        height: 100%;
        margin: 0%;
        flex-direction: column;
        justify-content: center;
        justify-content: space-evenly;
        justify-content: space-between;
    }
    #creativitySwiper .InnerSwiper .left{
        width: 100%;
        height: 90%;
        text-align: center;
        justify-content: space-evenly;
    }
    #creativitySwiper .InnerSwiper .left .upper{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
    }
    #creativitySwiper .InnerSwiper .left .upper .static{
        width: 50%;
        font-size: .6rem;
    }
    #creativitySwiper .InnerSwiper .left .upper .static::before{
        width: 0.3rem;
        height: 0.3rem;
        margin-left: -3.6rem;
        margin-top: 0.2rem;
    }
    #creativitySwiper .InnerSwiper .left .upper .title{
        width: 60%;
        font-size: 2.5rem;
    }
    #creativitySwiper .InnerSwiper .left .middle{
        display: flex;
    }
    #creativitySwiper .InnerSwiper .left .lower{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
    }
    #creativitySwiper .InnerSwiper .left .lower .details{
        width: 80%;
        font-size: 0.6rem;
        line-height: 1;
    }
    #creativitySwiper .InnerSwiper .left .lower .borrow{
        font-size: 1.2rem;
        margin-bottom: 0;
    }
    #creativitySwiper .InnerSwiper .left .lower .button{
        margin-top: .3rem;
    }
    #creativitySwiper .InnerSwiper .left .lower .button .knowMoreButton{
        font-size: .7rem;
        padding: 0.4rem .8rem
    }

    #creativitySwiper .InnerSwiper .right{
        width: 100%;
        height: 40%;
        margin-right: 0%;
        margin-left: 0%;
        display: none;
    }
    #creativitySwiper .InnerSwiper .right .outerImg .imageBorder{
        width: 70%;
        height: 25%;
    }
}
