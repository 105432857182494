.swiperBackDiv{
  height: 100vh;
  width: 50rem;
  // position: relative;
  // height: 100%;
}
.mySwiper{
  margin-bottom: -14rem;
}
.mySwiper .swiper {
  width: 100%;
  height: 50%;
}
.mySwiper .swiper-wrapper{
  width: 100%;
  height: 30%;
}
.mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper .box {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-out;
}
// .swiper-slide:nth-child(1) .box{
// .swiper-slide .box:nth-child(1){
// .swiper-slide:n .box:nth-child(1){
//   margin-bottom: 6rem;
// }
// .swiper-slide .box{
//   margin-bottom: 2rem;
// }
// .swiper-slide:nth-child(1){
//   padding-top: 8rem;
// }
// .swiper-slide:nth-child(2){
//   padding-top: 5rem;
// }
// .swiper-slide:nth-child(3){
//   padding-top: 1rem;
// }
// .swiper-slide:nth-child(4){
//   padding-top: 5rem;
// }
// .swiper-slide:nth-child(5){
//   padding-top: 8rem;
// }

// .imageBox {
//   width: 10rem;
//   height: 10rem;
//   background-color: aqua;
// }

.mySwiper .box:hover {
  transform: scale(1.2);
}
.mySwiper .swiper-slide{
  margin-top: 20rem;
  transition: 100ms ease;
}
.mySwiper .swiper-slide-active{
  margin-top: 3rem;
}
.mySwiper .swiper-slide-prev{
  margin-right: 3vw;
  margin-left: -3vw;
}
.mySwiper .swiper-slide-next,.mySwiper .swiper-slide-prev{
  margin-top: 7rem;
}
.mySwiper .swiper-slide-next{
  margin-left: 3vw;
  margin-right: -3vw;
}
// .mySwiper .swiper-slide-duplicate-prev{
//   margin-top: 20rem;
// }
// .imageBox:nth-child(1){
//   padding-top: 8rem;
// }
// .imageBox:nth-child(2) {
//   background-color: aqua;
//   padding-top: 4rem;
// }
// .imageBox:nth-child(3) {
//   background-color: blue;
//   padding-top: 1rem;
// }
// .imageBox:nth-child(4) {
//   background-color:#00a16c;
//   padding-top: 4rem;
// }
// .imageBox:nth-child(5) {
//   background-color: brown;
//   padding-top: 8rem;
// }
// .imageBox:nth-child(6) {
//   background-color: brown;
//   padding-top: 8rem;
// }
// .imageBox:nth-child(7) {
//   background-color: brown;
//   padding-top: 8rem;
// }
.navigationBUtton{
  // background-color: antiquewhite;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
}
.navigationBUtton .button{
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
  color: black;
  font-size: 1rem;
  background-color: #b0b09b;
  cursor: pointer;
  transition: 100ms ease;
  user-select: none;
}
.navigationBUtton .button:hover{
  background-color: #595954;
  color: white;
}
.navigationBUtton .button:active{
  background-color: #454540;
}

// Test
.swiperCircle {
  // width: 100vw;
  width: 100%;
  height: 18vw;
  @media (max-width:500px) {
    height: 45vw;
    }
  // height: 50%;
  // margin-top: -8rem;
}
.swiperCircle .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiperCircle .box {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 800ms ease-in-out;
  transform: scale(0);
  overflow: hidden;
  border: solid 10px #DDDDDD;
}
.swiperCircle .box.LogoLarge{
  background-color: #88C7F5;
}
.swiperCircle .box.AppDevLarge{
  background-color: #BD7087;
}
.swiperCircle .box.WebDevLarge{
  background-color: #527295;
}
.swiperCircle .box.UiUxLarge{
  background-color: #7C5494;
}
.swiperCircle .box.SocialMediaLarge{
  background-color: #DA7F6B;
}
.swiperCircle .box img{
  width: 60%;
  height: 60%;
  object-fit: contain;
  // object-fit: cover;
}
.swiper-fade .swiper-slide-active .box {
  transform: scale(0);
}
.swiperCircle.swiper-fade .swiper-slide-active .box {
  transform: scale(1);
}