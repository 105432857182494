.knowMoreButton{
    color: #101820;
    background-color: #DDDDDD;
    font-size: 1.2rem;
    padding: .5rem 1rem;
    border-radius: .3rem;
    cursor: pointer;
    transition: 300ms;
    font-weight: 600;
    border: none;
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 4px 4px rgba(0, 0, 0, 0.16);

}
.knowMoreButton:hover{
    color: #000000;
    box-shadow: none;
    // transform: scale(1.12);
    // transform: skew(1 ,2);
    transform: skew(-10deg) scale(1.12);

    // background-color: #dddddddf;

}
.knowMoreButton:active{
    background-color: #fdfdfde4;
    transform: skew(-8deg) scale(1.08);

}