@import url('https://fonts.googleapis.com/css2?family=Laila&display=swap');
$black : #101820;
$white : #DDDDDD;
#ContactUs.close{
    opacity: 0;
    // margin-left: 100vw;
    transition: 300ms ease;
}
#ContactUs{
    position: fixed;
    z-index: 4;
    left: 0;
    width: 100%;
    opacity: 1;

    height: 100vh;
    // background-color: black;
    // color: white;
    display: flex;
    display: none;
    transition: 300ms ease;
    color: $black;
}
#ContactUs .outer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #00000065;
}
#ContactUs .left{
    width: 8rem;
    height: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
}
// #ContactUs .right{
//     width: 100%;
//     width: 75%;
//     margin-left: 5%;
//     height: 100%;
//     // background-color: black;
//     display: grid;
//     align-items: center;
// }
#ContactUs.close .outer .inner{
    margin-left: 200vw;
    transition: 600ms ease;
}
#ContactUs .outer .inner{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 80%;
    height: 80%;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #E6F2FF;
    // background-color: gray;
    box-shadow: 0 0 30px 10px #00000093;
    transition: 600ms ease;
    position: relative;
}

#ContactUs .outer .inner .cross{
    display: flex;
    position: absolute;
    align-items: baseline;
    justify-content: flex-end;
    color:#101820;
    height: 93%;
    width: 96%;
}
#ContactUs .outer .inner .cross .crossButton{
    cursor: pointer;
    display: flex;
    font-size: 1.8rem;
    transition: 200ms ease-in-out;
}
#ContactUs .outer .inner .cross .crossButton:hover{
    transform: scale(1) rotate(90deg);
}
#ContactUs .outer .inner .cross .crossButton:active{
    transform: scale(.8) rotate(90deg);
}
#ContactUs .outer .inner .realContactUsDiv{
    // margin: 2rem;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 85%;
    z-index: 1;
}
#ContactUs .outer .inner .realContactUsDiv .innerTop{
    width: 100%;
    height: 20%;
}

#ContactUs .outer .inner .subtitle{
    font-family: 'Laila', sans-serif;
    display: flex;
    font-size: 1rem;
    align-items: center;
}
#ContactUs .outer .inner .subtitle::before{
    position:absolute;
    width: .5rem;
    height: .5rem;
    margin-left: -1rem;
    border-radius: 50%;
    content: '';
    background-color: $black;
}
#ContactUs .outer .inner .heading{
    font-size: 3.5rem;
    white-space: nowrap;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
}
#ContactUs .outer .inner .form{
    margin-top: 2rem;
    margin-top: 2%;
    height: 78%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // overflow: auto;
}
#ContactUs .outer .inner .form .formUpper{
    height: 10%;
    width: 100%;
}
#ContactUs .outer .inner .form .formUpper .title{
    font-size: 1.5rem;
    // margin-bottom: 1rem;
}
#ContactUs .outer .inner .form .formUpper hr{
    width: 100%;
    margin-top: .3rem;
    // margin-bottom: 3rem;
    height: 1px;
    background-color: $black;
    // border: none;
}
#ContactUs .outer .inner .form hr::before{
    position: absolute;
    content: '';
    height: 4px;
    margin-top: -1.5px;
    margin-top: -1.9px;
    margin-left: -1px;
    border-radius: 5rem;
    width: 17rem;
    background-color: $black;
}
#ContactUs .outer .inner .form .lower{
    display: flex;
    width: 98%;
    height: 82%;
}
#ContactUs .outer .inner .form .lower .left{
    display: flex;
    width: 50%;
    height: 100%;
}

#ContactUs .outer .inner .form .lower .left form{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    justify-content: space-between;
}
#ContactUs .outer .inner .form .lower .left form .alertMessage{
    position: absolute;
    font-size: .8rem;
    width: 100%;
    color: #0a00a8;
    font-weight: bolder;
    text-align: center;
    bottom: -2.5rem;
    animation: blink 1s infinite;
}
@keyframes blink {
    0%{
        color: #0a00a8;
    }
    59.9%{
        color: #0a00a8;
    }
    60%{
        color: transparent;
    }
    100%{
        color: transparent;
    }
}
#ContactUs .outer .inner .form .lower .left form label{
    font-size: 1rem;
    font-size: .9rem;
    font-weight: 500;
    color: $black;
    // margin-bottom: .8rem;
}
#ContactUs .outer .inner .form .lower .left form input{
    font-size: .9rem;
    background-color: transparent;
    padding: 0.6rem 0.5rem;
    margin-bottom: 0.8rem;
    border-radius: 0.3rem;
    border: none;
    box-shadow: 0 0 5px 0px #0000002e;
    transition: 300ms ease;
    outline: none;
    color: $black;
}
#ContactUs .outer .inner .form .lower .left form input::placeholder{
    // color: #212121a1;
    font-size: .8rem;
    font-weight: 300;
}
#ContactUs .outer .inner .form .lower .left form input:focus{
    box-shadow: 0 0 8px 0px #00000032;
    transform: scale(.99);
}
#ContactUs .outer .inner .form .lower .left form button{
    // width: 5rem;
    // width: auto;
    // margin-top: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-left: auto;
    font-size: 1.2rem;
    padding: .5rem 1.2rem;
    border-radius: .3rem;
    cursor: pointer;
    background-color: $black;
    color: $white;
    transition: 300ms ease;
}
#ContactUs .outer .inner .form .lower .left form button:hover{
    // transform: scale3d(1, 1, 1);
    // transform: scale3d(1, 1, 1);
    transform: scale(1.05) skew(-10deg);
}
#ContactUs .outer .inner .form .lower .left form button:focus{
    transform: scale(1) skew(-5deg);
    border-radius: .6rem;
}
#ContactUs .outer .inner .form .lower .right{
    display: block;
    padding-left: 5%;
    width: 45%;
    border-left: solid 2px $black;
}
#ContactUs .outer .inner .form .lower .right .contactInfo{
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .conteactInfoUpper{
    height: 10%;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .conteactInfoUpper .infoHeading{
    width: 14rem;
    font-size: 1.5rem;
    font-weight: 600;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .conteactInfoUpper hr{
    height: 1px;
    background-color: $black;
    z-index: 0;
    margin-bottom: 0;
    margin-top: .3rem;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .conteactInfoUpper hr::before{
    position: absolute;
    content: '';
    height: 4px;
    // margin-top: -.5px;
    margin-left: -1px;
    border-radius: 5rem;
    width: 11rem;
    background-color: $black;
}

#ContactUs .outer .inner .form .lower .right .contactInfo .detailsDiv{
    line-height: 2;
    width: 100%;
    height: 80%;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .detailsDiv .infoTitle{
    font-size: .9rem;
    font-weight: 500;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .detailsDiv .infoDetails{
    font-size: .9rem;
    font-weight: 300;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .detailsDiv .infoDetails a{
    text-decoration: none;
    color: #101820;
    transition: 300ms ease;
}
#ContactUs .outer .inner .form .lower .right .contactInfo .detailsDiv .infoDetails a:hover{
    // font-weight: 400;
    color: blue;
}
@media (max-width:500px) {
    #ContactUs{
        justify-content: center;
        height: 100%;
        top: 0;
    }

    #ContactUs .left{
        width: 0;
        height: auto;
    }
    #ContactUs .outer{
        width: 100%;
        height: 100%;
        align-items: baseline;
        height: auto;
        // margin-bottom: 2rem;
    }
    #ContactUs.close .outer .inner{
        margin-left: 0;
    }
    #ContactUs .outer .inner{
        width: 100%;
        height: 90%;
        height: 100%;
        border-radius: 0rem;
    }

    #ContactUs .outer .inner .realContactUsDiv{
        height: 90%;
    }
    #ContactUs .outer .inner .subtitle{
        font-size: .8rem;
    }
    #ContactUs .outer .inner .heading{
        font-size: 2rem;
    }
    #ContactUs .outer .inner .form{
        overflow: auto;
        overflow-x: hidden;
    }
    #ContactUs .outer .inner .form .lower .left form .alertMessage{
        top: 2.5rem;
        left: 0;
    }
    #ContactUs .outer .inner .form .title{
        font-size: 1.3rem;
    }
    #ContactUs .outer .inner .form hr{
        // margin-bottom: 3rem;
    }
    #ContactUs .outer .inner .form hr::before{
        width: 15rem;
        display: none;
    }
    #ContactUs .outer .inner .form .lower{
        display: flex;
        flex-wrap: wrap;
    }
    #ContactUs .outer .inner .form .lower .left{
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
    #ContactUs .outer .inner .form .lower .right{
        padding: 0;
        width: 100%;
        border: none;
    }
    #ContactUs .outer .inner .form .lower .right .contactInfo .infoHeading{
        font-size: 1.3rem;
    }
    #ContactUs .outer .inner .form .lower .right .contactInfo hr::before{
        width: 9rem;
    }
}