#PreviewPopUpDiv.close{
    opacity: 0;
    transition: 300ms ease-in-out;
}
#PreviewPopUpDiv{
    // opacity: 1;
    // opacity: 0;
    display: flex;
    display: none;
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;
    background-color: #000000b5;
    background-color: #000000cf;
    // background-color: #000000e8;
    transition: 300ms ease-in-out;
}

#PreviewPopUpDiv .cross{
    position: absolute;
    color: white;
    background-color: black;
    top: 5%;
    font-size: 2rem;
    right: 4%;
    display: flex;
    cursor: pointer;
    transition: 200ms ease-in-out;
}
#PreviewPopUpDiv .cross:hover{
    transform: scale(1.1) rotate(90deg);
}
#PreviewPopUpDiv .cross:active{
    transform: scale(1) rotate(90deg);
}
#PreviewPopUpDiv.close .previewer{
    transform: scale(0);
    
}
#PreviewPopUpDiv .previewer{
    // background-color: #ffffff3e;
    transform: scale(1);
    width: 80%;
    height: 80%;
    transition: 300ms ease-in-out;
}
#PreviewPopUpDiv .previewer img,#PreviewPopUpDiv .previewer video{
    // width: 100%;
    // height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    // object-fit: cover;
}
@media (max-width:500px) {
    #PreviewPopUpDiv{
        top: 0;
    }
}