#NeedToHear.close{
    opacity: 0;
    // margin-left: 100vw;
    transition: 300ms ease;
}

#NeedToHear{
    position: fixed;
    z-index: 4;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 1;
    display: none;
    transition: 300ms ease;
}
#NeedToHear .outer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #00000065;
}
#NeedToHear.close .outer .inner{
    margin-left: 200vw;
    transition: 600ms ease;
}
#NeedToHear .outer .inner{
    width: 80%;
    // width: 100%;
    height: 80%;
    display: flex;
    text-align: left;
    border-radius: 2rem;
    background-color: white;
    background-color: #E6F2FF;
    box-shadow: 0 0 30px 10px #00000093;
    transition: 600ms ease;
}
// #NeedToHear .outer .inner::before{
//     content: "";
//     width: 2rem;
//     margin-left: -1.5rem;
//     height: 5rem;
//     bottom: calc(50% - 2.5rem);
//     border-radius: .5rem;
//     background-color: white;
//     position: absolute;
//     // left: 0;
// }
#NeedToHear .outer .inner .realNeedToHearDiv{
    // margin: 2rem;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
#NeedToHear .outer .inner .realNeedToHearDiv .cross{
    display: flex;
    position: absolute;
    align-items: baseline;
    justify-content: flex-end;
    color:#101820;
    height: 94%;
    width: 98%;
}
#NeedToHear .outer .inner .realNeedToHearDiv .cross .crossButton{
    cursor: pointer;
    display: flex;
    font-size: 1.8rem;
    transition: 200ms ease-in-out;
}
#NeedToHear .outer .inner .realNeedToHearDiv .cross .crossButton:hover{
    transform: scale(1) rotate(90deg);
}
#NeedToHear .outer .inner .realNeedToHearDiv .cross .crossButton:active{
    transform: scale(.8) rotate(90deg);
}
#NeedToHear .outer .inner .realNeedToHearDiv .portfolioLeft{
    user-select: none;
    text-align: center;
    width: 3rem;
    min-width: 2rem;
    font-size: 1rem;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
}
#NeedToHear .outer .inner .realNeedToHearDiv .right{
    height: 85%;
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 7%;
    z-index: 1;
}
#NeedToHear .outer .inner .realNeedToHearDiv .right .upperheading{
    width: 100%;
    font-size: 1.3rem;
    font-weight: bold;
}
#NeedToHear .outer .inner .realNeedToHearDiv hr{
    width: 100%;
    margin-top: .3rem;
    height: 1px;
    background-color: black;
}
#NeedToHear .outer .inner .realNeedToHearDiv hr::before{
    position: absolute;
    content: '';
    height: 4px;
    margin-top: -2px;
    margin-left: -1px;
    border-radius: 5rem;
    width: 22rem;
    background-color: black;
}

// #NeedToHear .outer .inner .realNeedToHearDiv .search input::placeholder{
// }
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer{
    display: flex;
    justify-content: space-between;
    height: 100%;
    height: 85%;
    overflow: auto;
    margin-top: 1rem;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .button{
    // height: 100%;
    width: 2rem;
    display: flex;
    justify-content: center;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .button .iconInner{
    margin-bottom: auto;
    font-size: 1.3rem;
    z-index: 1;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .button .iconInner:hover{
    transform: scale(1.2);
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle{
    width: 90%;
    height: 100%;
    // overflow: auto;
    // background-color: blanchedalmond;
    display: flex;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .swiper-slide{
    // width: 100%;
    overflow: visible;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .outer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .outer::before{
    background-color: gray;
    content: '';
    width: 1px;
    margin-top: 4rem;
    margin-left: -15px-1px;
    left: auto;
    height: 70%;
    position: absolute;
    display: none;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .container{
    display: flex;
    width: 100%;
    height: 100%;
    color: white;
    user-select: none;
    justify-content: center;
}

#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box{
    width: 100%;
    height: 100%;
    font-size: 1rem;
    user-select: none;
    color: #101820;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box .title{
    cursor: pointer;
    border: solid 1px black;
    width: 100%;
    min-height: 1.1rem;
    width: 10rem;
    border-radius: 0.3rem;
    font-size: .8rem;
    padding: 0.5rem .8rem;
    height: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box ul{
    list-style: none;
    width: 100%;
    // max-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    // overflow: auto;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box .details{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;
    height: 100%;
    overflow: auto;
    font-size: 1rem;
}
#NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box .details::before{
    background-color: gray;
    content: '';
    width: 1px;
    margin-right: -15px;
    left: auto;
    height: 100%;
    z-index: -1;
    position: absolute;
    // (max-width: 500px;){
    //     margin-right: -5px;
    // }
}
@media (max-width: 1280px) {
    #NeedToHear .outer .inner .realNeedToHearDiv .search{
        margin-top: .5rem;
    }
}
@media (max-width: 1080px) {
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box .details::before{
    margin-right: -5px;
    }
    
}
@media (max-width: 500px) {
    #NeedToHear{
        width: 100%;
        height: 90vh;
        top: 0;
    }
    #NeedToHear.close .outer .inner{
        margin-left: 0;
    }
    #NeedToHear .outer .inner{
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .search{
        margin: 1rem 0;
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer{
        margin-right: 2rem;
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle{
        width: 80%;
    }
    
}
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box ul li{
        margin-top: 1rem;
        // cursor: pointer;
        display: flex;
        justify-content: center;
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box ul li .option{
        cursor: pointer;
        transition: 300ms ease;
        transform: scale(.95);
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box ul li .option:hover{
        // background-color: #4785c7;
        transform: scale(1);
        font-weight: 500;
        // color: black;
    }
    #NeedToHear .outer .inner .realNeedToHearDiv .needToHearSwiperContainer .middle #needToHearSwiperContainer .box ul li:nth-child(1){
        margin-top: 0;
}